
import { defineComponent, ref, reactive } from '@vue/composition-api'

export default defineComponent({
  name: 'CommonDeleteDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const hasCancelButtonFocus = ref(false)

    return reactive({
      state: {
        hasCancelButtonFocus,
      },
    })
  },
})
