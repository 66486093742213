var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "500"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('cancel');
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.hasCancelButtonFocus ? null : _vm.$emit('delete');
      }]
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._t("title")], 2), _c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._t("default")], 2), _c('v-card-text', [_vm._t("warning")], 2), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "loading": _vm.isLoading,
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.delete')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }